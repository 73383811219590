.activeArea {
  position: absolute;
  top: 0px;
  left: 370px;
  right: 0px;
  height: 100vh;
}

.deactiveArea {
  position: absolute;
  top: 0px;
  left: 0px !important;
  right: 0px;
  height: 100vh;
}

.activeAreaWebview {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 120px;
  height: 100vh;
}

.live-tracking-order {
  position: fixed;
}
.live-tracking-order .leaflet-control-zoom {
  display: none;
}
