.live-tracking-order {
  position: fixed;
}
.live-tracking-order .leaflet-control-zoom {
  display: none;
}

.pulsing-circle {
  animation: pulseEffect 2s infinite;
}

@keyframes pulseEffect {
  0% {
    r: 0; /* Mulai dari radius 0 */
    opacity: 0.6;
  }
  50% {
    r: 100%; /* Membesar hingga radius maksimal */
    opacity: 0.2;
  }
  100% {
    r: 0; /* Kembali ke radius 0 */
    opacity: 0;
  }
}

.marker-shadow {
  filter: drop-shadow(0px 0px 12px rgba(100, 100, 100, 0.08))
    drop-shadow(0px 0px 4px rgba(7, 7, 7, 0.08));
}
