.marker-container {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1 !important;
}

#marker-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  border-radius: 50%;
  background-color: royalblue;
  width: 150px;
  height: 150px;
  position: absolute;
  opacity: 0;
  animation: scaleIn 2s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
}

.whiteBorder {
  border: 1px solid white;
}

.transparent {
  background-color: transparent;
}

@keyframes scaleIn {
  from {
    transform: scale(0.5, 0.5);
    opacity: 0.5;
  }
  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}
