.activeArea {
  position: absolute;
  top: 0px;
  left: 370px;
  right: 0px;
  height: 100vh;
}

.deactiveArea {
  position: absolute;
  top: 0px;
  left: 0px !important;
  right: 0px;
  height: 100vh;
}

.activeAreaWebview {
  position: absolute;
  /* top: 86px; */
  left: 0px;
  right: 0px;
  bottom: 120px;
  height: 100vh;
}

.leaflet-control-container > .leaflet-top.leaflet-left {
  right: 24px;
  bottom: 36px;
  left: unset;
  top: unset;
}
