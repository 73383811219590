.custom-leaflet-marker img {
  width: 50px;
  height: 50px;
}

.marker-info {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 8px 12px 8px 12px;
  border-radius: 8px 0px 8px 8px;
  text-align: center;
  position: relative;
  border: none;
}

.absolute-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 26px;
  height: 26px;
  border-radius: 999px;
  border: 1px solid var(--primary-galaxy-11, rgba(25, 77, 160, 1));
  background: rgba(36, 110, 229, 1);
  top: -10px;
  right: -15px;
}
.absolute-icon-close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 26px;
  height: 26px;
  border-radius: 999px;
  background: white;
  border: 1px solid var(--primary-galaxy-1, rgba(211, 211, 211, 1));
  top: -10px;
  right: -15px;
}

.custom-marker-with-html .marker-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 10px;
  border-radius: 8px;
}

.marker-button button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 24px;
  border-radius: 99px;
  padding: 8px;
  font-size: 12px;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.marker-name {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 42px;
}

span {
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.001em;
  text-align: left;
}
.custom-marker-icon {
  width: 100%;
  height: auto;
  image-rendering: crisp-edges;
  image-rendering: -webkit-optimize-contrast;
}

.popup-right .leaflet-popup-content-wrapper {
  /* Adjust position to the right */
  transform: translate(64px, 32px);
}

.popup-left .leaflet-popup-content-wrapper {
  /* Adjust position to the left */
  transform: translateX(-64px);
}

.popup-bottom .leaflet-popup-content-wrapper {
  /* Adjust position below */
  transform: translateY(10px);
}
